/* eslint-disable jsx-a11y/anchor-is-valid */
/* This example requires Tailwind CSS v2.0+ */
import React from 'react';
import { Link } from 'gatsby';
import { trackEvent } from '../models/analytics';

export default function InsuranceCTA() {
	return (
		<div className="">
			<div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
				<div className="flex flex-col">
					<h3 className="font-bold lg:w-2/3">
						Is your insurance company interested in working with awesome, innovative companies?
					</h3>
					<br />
					<p className="pb-5 xl:w-2/3">
						Buddy powers products from global, A+ Rated insurance carriers, and we're dedicated to making both sides of the insurance relationship as efficient as possible. We're constantly helping carrier partners streamline their digital distribution through our gateway.
					</p>
					<p className="mt-2">
						Interested in becoming gateway compatible?

					</p>
					<div className="md:w-1/3 flex justify-start pt-5">
						<Link
							to="/contact"
							onClick={() => trackEvent({ eventType: 'cta_click_insurance' })}
							className="buddy-btn-mobelux w-full mb-9"
						>
							Contact Us
						</Link>
						{/* 12-19-22 Leaving this here bc the idea is to add them back as soon as we have a good solution */}
						{/* <Link
							to="https://meetings.hubspot.com/dillon-reed"
							onClick={() => trackEvent({ eventType: 'cta_click_insurance_demo' })}
							className="buddy-btn-mobelux buddy-red w-full mb-9 ml-3"
						>
							Book a Demo
						</Link> */}
					</div>
				</div>

			</div>
		</div>
	);
}
