import React from 'react';
import { isMobile } from 'react-device-detect';
import CurvedHorizontalDivider from './subcomponents/CurvedHorizontalDivider';

import CurvedHorizontalDividerMobile from './subcomponents/CurvedHorizontalDividerMobile';

export default function RBO() {
	return (

		<div className="sm:px-8 px-8 flex flex-col items-center mt-12 xl:my-14" style={{ overflow: 'hidden' }}>
			{!isMobile ? (
				<CurvedHorizontalDivider />
			) : (
				<CurvedHorizontalDividerMobile />
			)}
			<div className="hidden lg:flex lg:items-center lg:justify-center lg:my-12 lg:xl:my-28">

				{/* <CornerAccent className="absolute lg:left-44 lg:-bottom-28 2xl:left-oneFifth 2xl:bottom-10 hidden xl:block" /> */}
				<h3 className="text-center relative rbo-item leading-10">
				Customizable UI | Fully Managed Compliance | Quick Product Onboarding |
					{' '}
					<br />
					Licensed in All 50 States & DC
				</h3>
			</div>

			<div className="w-full flex flex-col items-center text-center justify-between mt-12 lg:hidden h-auto ">
				<div className="flex flex-col justify-start items-between ">
					<p className="rbo-item">Payment Processor Compatible</p>
					<h3 className="rbo-item"> - </h3>
					<h3 className="rbo-item">Customizable UI</h3>
					<h3 className="rbo-item"> - </h3>
					<h3 className="rbo-item">Fully Managed Compliance</h3>
					<h3 className="rbo-item"> - </h3>
					<h3 className="rbo-item">Quick Product Onboarding</h3>
					<h3 className="rbo-item"> - </h3>
					<h3 className="mt-1.5 mb-0 rbo-item">Licensed in All 50 States & DC</h3>
				</div>
			</div>
		</div>
	);
}
