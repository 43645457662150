/* This example requires Tailwind CSS v2.0+ */
import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { isMobile } from 'react-device-detect';
import { trackEvent } from '../models/analytics';
import heroGraphic from '../assets/HeroGraphic.svg';

export default function Hero() {
	return (
		<div className="relative bg-white">
			<main className="lg:relative">
				<div className="flex mx-auto max-w-7xl w-full pt-8 pb-0 xl:pb-20  lg:pt-20 lg:pb-16 2xk:py-24 text-left">
					<div className="relative px-4 lg:w-3/5 sm:px-8 xl:pr-16">
						<img
							src={heroGraphic}
							alt="Swirl Line"
							className="-left-20 -top-12 absolute overflow-hidden"
						/>
						<div className="relative">
							{/* for some reason, that relative class is what keeps our text in front of the swirly, otherwise the swirly goes in front. z-index didn't fix it. */}
							{!isMobile ? (
								<h1>
									The simplest way to offer insurance on the internet
								</h1>
							) : (
								<h1>
									The simplest way to offer insurance on the internet
									{/* <br />
									to your application */}
								</h1>
							)}
							{' '}
							<h3 className="">
								Connect once, and leave bulky API integrations behind forever.
							</h3>
						</div>
						<div className="mt-10 sm:flex sm:justify-center lg:justify-start">
							<div className="flex flex-col">
								<Link
									to="/contact"
									onClick={() => trackEvent({ eventType: 'cta_click_hero' })}
									className="buddy-btn-mobelux w-full md:w-1/2 lg:w-2/3 mb-9"
								>
									Get Access
								</Link>
								<p>
									Buddy is the insurance gateway for software companies. Our drop-in snippet enables you to embed any insurance product into your transaction flow in minutes.
								</p>
								<p className="mt-4">
									And you can leave all the insurance-y stuff to us.
								</p>
							</div>
							{/*  */}
						</div>
					</div>
					<div className="hidden lg:flex justify-end items-center w-full lg:w-1/2 lg:h-4/5 xl:h-full 2xl:h-full 2xl:pr-20">
						<StaticImage
							alt="buddy on desktop and mobile"
							placeholder="blur"
							src="../assets/hero_device_mockup.png"
							loading="eager"
						/>
					</div>
				</div>
			</main>
		</div>
	);
}
