import React from 'react';

const Booking = ({
	width = '32',
	height = '32',
	className = '',
}) => (
	<svg width={width} height={height} className={className} viewBox="0 0 256 223" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M4.63227 153.652C13.6412 125.308 65.9133 121.45 64.9682 108.332C64.0084 95.0127 8.20451 102.647 0.538521 80.8407C-8.33642 55.5958 94.032 -8.88973 193.282 1.02802C226.725 4.3699 248.093 15.7516 248.4 28.9165C248.847 48.1321 202.706 52.2354 196.404 81.0623C189.719 111.645 261.08 131.415 255.711 165.133C252.104 187.787 211.812 224.068 130.145 222.976C48.477 221.884 -4.31551 181.804 4.63227 153.652Z" fill="url(#paint0_linear_369:315)" />
		<g opacity="0.85">
			<path d="M170.5 61H153.5V52.5H145V61H111V52.5H102.5V61H85.5C80.825 61 77 64.825 77 69.5V154.5C77 159.175 80.825 163 85.5 163H170.5C175.175 163 179 159.175 179 154.5V69.5C179 64.825 175.175 61 170.5 61ZM170.5 154.5H85.5V95H170.5V154.5ZM170.5 86.5H85.5V69.5H102.5V78H111V69.5H145V78H153.5V69.5H170.5V86.5Z" fill="black" fillOpacity="0.85" />
		</g>
		<defs>
			<linearGradient id="paint0_linear_369:315" x1="128" y1="0" x2="128" y2="223" gradientUnits="userSpaceOnUse">
				<stop stopColor="#D6F1F2" />
				<stop offset="1" stopColor="#F8F6D2" />
			</linearGradient>
		</defs>
	</svg>

);

export default Booking;
