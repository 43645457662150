import React from 'react';

const StartUp = ({
	width = '32',
	height = '32',
	className = '',
}) => (
	<svg width={width} height={height} className={className} viewBox="0 0 256 223" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M4.63227 153.652C13.6412 125.308 65.9133 121.45 64.9682 108.332C64.0084 95.0127 8.20451 102.647 0.538521 80.8407C-8.33642 55.5958 94.032 -8.88973 193.282 1.02802C226.725 4.3699 248.093 15.7516 248.4 28.9165C248.847 48.1321 202.706 52.2354 196.404 81.0623C189.719 111.645 261.08 131.415 255.711 165.133C252.104 187.787 211.812 224.068 130.145 222.976C48.477 221.884 -4.31551 181.804 4.63227 153.652Z" fill="url(#paint0_linear_369:316)" />
		<g opacity="0.85">
			<path d="M74.167 59.5833C74.167 58.4561 73.7192 57.3751 72.9222 56.578C72.1252 55.781 71.0442 55.3333 69.917 55.3333C68.7898 55.3333 67.7088 55.781 66.9118 56.578C66.1148 57.3751 65.667 58.4561 65.667 59.5833V164.417C65.667 166.763 67.571 168.667 69.917 168.667H173.334C174.461 168.667 175.542 168.219 176.339 167.422C177.136 166.625 177.584 165.544 177.584 164.417C177.584 163.289 177.136 162.208 176.339 161.411C175.542 160.614 174.461 160.167 173.334 160.167H74.167V59.5833Z" fill="black" fillOpacity="0.85" />
			<path d="M186.254 88.087C187.005 87.2814 187.413 86.2158 187.394 85.1147C187.374 84.0137 186.928 82.9632 186.15 82.1845C185.371 81.4058 184.321 80.9598 183.219 80.9404C182.118 80.9209 181.053 81.3296 180.247 82.0803L147.834 114.494L126.754 93.4137C125.957 92.6178 124.877 92.1708 123.751 92.1708C122.624 92.1708 121.544 92.6178 120.747 93.4137L86.7472 127.414C86.3297 127.803 85.9947 128.272 85.7625 128.793C85.5302 129.315 85.4053 129.877 85.3952 130.448C85.3851 131.019 85.4901 131.586 85.7038 132.115C85.9176 132.644 86.2358 133.125 86.6393 133.528C87.0429 133.932 87.5236 134.25 88.0528 134.464C88.582 134.677 89.1489 134.782 89.7195 134.772C90.2902 134.762 90.8529 134.637 91.3743 134.405C91.8956 134.173 92.3648 133.838 92.7539 133.42L123.751 102.424L144.831 123.504C145.627 124.3 146.708 124.747 147.834 124.747C148.96 124.747 150.04 124.3 150.837 123.504L186.254 88.087Z" fill="black" fillOpacity="0.85" />
		</g>
		<defs>
			<linearGradient id="paint0_linear_369:316" x1="128" y1="0" x2="128" y2="223" gradientUnits="userSpaceOnUse">
				<stop stopColor="#D6F1F2" />
				<stop offset="1" stopColor="#F8F6D2" />
			</linearGradient>
		</defs>
	</svg>

);

export default StartUp;
