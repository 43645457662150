/* This example requires Tailwind CSS v2.0+ */
import React, { useEffect, useState } from 'react';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import xml from 'react-syntax-highlighter/dist/esm/languages/hljs/xml';
import vs2015 from 'react-syntax-highlighter/dist/esm/styles/hljs/vs2015';
import getOperatingSystem from '../helpers';

SyntaxHighlighter.registerLanguage('xml', xml);

const codeSnippet = `<script src="https://embed.buddy.insure/script.js"></script>
<script type="application/javascript">
	var options = {
		"theme": "base",
		"appSettings": {
			"includeCheckout": true
		},
		"policyInfo": {
			"offering": "BUDDY_ACCIDENT_V2",
		}
	};

	function addToCart(payload) {
		// see Buddy documentation for more info
	}

	options.stage = 'PRODUCTION';
	options.ADD_TO_CART = addToCart;

	buddy_setup(options);
</script>;`;

export default function DevCallout() {
	const [os, setOs] = useState('');
	useEffect(() => {
		setOs(getOperatingSystem(window));
	},
	[]);

	return (
		<div className="py-8 gradient overflow-hidden lg:py-10 mb-0 xl:mb-28 mt-12 xl:mt-0">
			<div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl ">
				<div className="flex flex-col lg:flex-row  xl:justify-start xl:items-center">
					<div className="xl:w-1/2 flex flex-col items-start">
						<h2 className="">
							Write Less Code
						</h2>

						<p className="xl:mt-3 max-w-1/4">
							Copy, paste, and get connected.
							<br />
							Then, get back to what matters most to you.
						</p>
					</div>
					<div className="mt-5 xl:mt-0 xl:w-1/2 my-auto mx-auto w-full">
						<SyntaxHighlighter className="rounded-md h-full max-h-full text-xs " style={vs2015}>
							{codeSnippet}
						</SyntaxHighlighter>
					</div>
				</div>
			</div>
		</div>
	);
}
