import React from 'react';
import Booking from '../assets/icons/Booking';
import Fintech from '../assets/icons/Fintech';
import Startup from '../assets/icons/StartUp';
import Broker from '../assets/icons/Broker';
import Insurance from '../assets/icons/Insurance';
import Ecommerce from '../assets/icons/Ecommerce';

const features = [
	{
		name: 'FOR BOOKING ENGINES',
		description: 'Use a single connection to configure, transact, and manage insurance products across your platform.',
		icon: <Booking width="96" height="96" />,
	},
	{
		name: 'FOR STARTUPS',
		description:
      'Buddy makes it easy to drop fully-managed insurance products into your build.',
		icon: <Startup width="96" height="96" />,
	},
	{
		name: 'FOR FINTECH',
		description:
      'Open your platform to a universe of insurance options to meet users\' needs.',
		icon: <Fintech width="96" height="96" />,
	},
	{
		name: 'FOR BROKERS, MGAs, and MGUs',
		description:
      'Quickly access a library of gateway-compatible products and increase conversion.',
		icon: <Broker width="96" height="96" />,
	},
	{
		name: 'FOR INSURTECH',
		description:
      'Simplify and standardize distribution of your products, across lines of business.',
		icon: <Insurance width="96" height="96" />,
	},
	{
		name: 'FOR E-COMMERCE',
		description:
      'Increase the value of your transactions with relevant insurance offerings—right at the point of sale.',
		icon: <Ecommerce width="96" height="96" />,
	},
];

export default function ForSection() {
	return (
		<div className="overflow-hidden">
			<div className="relative max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
				<div className="relative lg:grid lg:grid-cols-3 lg:gap-x-8">
					<div className="lg:col-span-1">
						<h2 className="">
							The Insurance Gateway...
						</h2>
					</div>
					<dl className="mt-10 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-10 lg:mt-0 lg:col-span-2">
						{features.map((feature) => (
							<div key={feature.name}>
								<dt>
									<div>{feature.icon}</div>
									<p className="mt-5 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
								</dt>
								<dd className="mt-2 text-base text-gray-500">{feature.description}</dd>
							</div>
						))}
					</dl>
				</div>
			</div>
		</div>
	);
}
