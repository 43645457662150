import React from 'react';

const Ecommerce = ({
	width = '32',
	height = '32',
	className = '',
}) => (
	<svg width={width} height={height} className={className} viewBox="0 0 256 223" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M4.63227 153.652C13.6412 125.308 65.9133 121.45 64.9682 108.332C64.0084 95.0127 8.20451 102.647 0.538521 80.8407C-8.33642 55.5958 94.032 -8.88973 193.282 1.02802C226.725 4.3699 248.093 15.7516 248.4 28.9165C248.847 48.1321 202.706 52.2354 196.404 81.0623C189.719 111.645 261.08 131.415 255.711 165.133C252.104 187.787 211.812 224.068 130.145 222.976C48.477 221.884 -4.31551 181.804 4.63227 153.652Z" fill="url(#paint0_linear_369:313)" />
		<g opacity="0.85">
			<path d="M70.625 148.125H123.75V167.25H102.5V175.75H153.5V167.25H132.25V148.125H185.375C187.065 148.123 188.686 147.451 189.881 146.256C191.076 145.061 191.748 143.44 191.75 141.75V63.125C191.748 61.4348 191.076 59.8145 189.881 58.6194C188.686 57.4243 187.065 56.752 185.375 56.75H70.625C68.9348 56.752 67.3145 57.4243 66.1194 58.6194C64.9243 59.8145 64.252 61.4348 64.25 63.125V141.75C64.252 143.44 64.9243 145.061 66.1194 146.256C67.3145 147.451 68.9348 148.123 70.625 148.125V148.125ZM72.75 65.25H183.25V139.625H72.75V65.25Z" fill="black" fillOpacity="0.85" />
		</g>
		<defs>
			<linearGradient id="paint0_linear_369:313" x1="128" y1="0" x2="128" y2="223" gradientUnits="userSpaceOnUse">
				<stop stopColor="#D6F1F2" />
				<stop offset="1" stopColor="#F8F6D2" />
			</linearGradient>
		</defs>
	</svg>

);

export default Ecommerce;
