import * as React from 'react';
import Header from '../components/Header';
import Hero from '../components/Hero';
import RBO from '../components/RBO';
import DevCallout from '../components/DevCallout';
import ForSection from '../components/ForSection';
import InsuranceCTA from '../components/InsuranceCTA';
import Footer from '../components/Footer';
import SEO from '../components/SEO';

const IndexPage = (props) => (

	<SEO {...props}>
		<main className="flex flex-col">
			<Header />
			<Hero />
			<RBO />
			<div id="developers">
				<DevCallout />
			</div>
			<div id="for-section">
				<ForSection />
			</div>
			<div id="insurance">
				<InsuranceCTA />
			</div>
			<Footer />
		</main>
	</SEO>
);

export default IndexPage;
